import { CartPropType } from "../types";

import { ReactComponent as Delete } from "../../../assets/icons/delete.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  clearCart,
  decrementQuantity,
  incrementQuantity,
  removeItem,
} from "../reducer";
import { IRootState } from "../../..";

type PropType = {
  cart: CartPropType;
  isDelete?: boolean;
};

const CartItemCard = ({ cart, isDelete }: PropType) => {
  const carts = useSelector((state: IRootState) => state.cart.value.cart);
  const country = useSelector((state: IRootState) => state.cart.value.country);
  const dispatch = useDispatch();

  const getTotalAmountForCartItem = () => {
    let subtotal = Number(cart.menu_total);

    if (cart.menu_addons) {
      cart.menu_addons.forEach((addon) => {
        subtotal += addon.addons_price * addon.addons_quantity;
      });
    }
    if (cart.menu_variation) {
      cart.menu_variation.forEach((variation) => {
        subtotal += variation.price ;
      });
    }
    return subtotal ;
  };

  const removeItemFromCart = (id: number) => {
    dispatch(removeItem(id));
  };

  const onIncreament = (id: number) => {
    dispatch(incrementQuantity(id));
  };
  const onDecrease = (id: number) => {
    dispatch(decrementQuantity(id));
  };

  const onRemoveItem = () => {
    if (carts.length === 1) {
      removeItemFromCart(cart.menu_id);
      dispatch(clearCart());
    } else {
      removeItemFromCart(cart.menu_id);
    }
  };
  return (
    <div className="flex text-black gap-3 border-b p-3 ">
      <div className="w-[25%]">
        <div
          className="w-20 h-20 bg-cover bg-center bg-no-repeat rounded-md"
          style={{ backgroundImage: `url(${cart.menu_image})` }}
        ></div>
      </div>
      <div className="flex flex-col gap-1 justify-between  w-full">
        <div className="font-medium text-sm">{cart.menu_name}</div>
        <div className="text-[#727272] text-xs">
          {cart.menu_variation.length > 0 && (
            <div className="flex gap-1">
              <div className="">Variation:</div>
              {cart.menu_variation.map((item) => (
                <div className="" key={item.label}>
                  {item.label},
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="text-[#727272] text-xs">
          {cart.menu_addons.length > 0 && (
            <div className="flex gap-1">
              <div className="">Addons:</div>
              {cart.menu_addons.map((item) => (
                <div className="" key={item.addons_id}>
                  ({item.addons_name},)
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="grid grid-cols-3 items-center gap-4  w-full">
          <div className="text-primary font-medium text-sm ">
            {country.country_currency_symbol} {getTotalAmountForCartItem()}
          </div>
          {isDelete && (
            <div className="flex gap-3 items-center">
              <div
                className={`cursor-pointer aspect-square w-4 h-4 rounded-full flex items-center justify-center text-white  ${
                  cart.menu_quantity > 1 ? "bg-primary" : "bg-[#E0E0E0]"
                }`}
                onClick={() => onDecrease(cart.menu_id)}
              >
                -
              </div>
              <div className="font-medium text-sm">{cart.menu_quantity}</div>

              <div
                className="cursor-pointer w-4 h-4 aspect-square rounded-full flex items-center justify-center text-white bg-primary"
                onClick={() => onIncreament(cart.menu_id)}
              >
                +
              </div>
            </div>
          )}
          {isDelete && <Delete className="w-4 h-4" onClick={onRemoveItem} />}
        </div>
      </div>
    </div>
  );
};

export default CartItemCard;
