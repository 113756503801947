import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Empty from "../../assets/icons/cart-empty.svg";
import Bike from "../../assets/icons/bike.svg";
import Cart from "../../assets/icons/cart-frame.svg";

import CartItemCard from "./component/CartItemCard";
import Button from "../../components/ui/button";
import { CartPropType } from "./types";
import { clearCart, setCart } from "./reducer";
import { IRootState } from "../..";

type PropType = {
  onClose: () => void;
};

const CartPage = ({ onClose }: PropType) => {
  const cart = useSelector((state: IRootState) => state.cart.value.cart);
  const country = useSelector((state: IRootState) => state.cart.value.country);
  const user = useSelector((state: IRootState) => state.user.value);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const handleBackgroundClick = (event: any) => {
    if (event.target === event.currentTarget) {
      handleClose();
    }
  };
  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      onClose();
      setIsClosing(false);
    }, 300); // 300ms is the duration of the CSS transition
  };

  const onShop = () => {
    dispatch(
      setCart({
        isActive: false,
      })
    );
    navigate("/home");
  };

  const onCheckout = () => {
    if (user.loggedIn) {
      setLoading(true);
      dispatch(
        setCart({
          isActive: false,
        })
      );
      navigate("/checkout");
    } else {
      dispatch(
        setCart({
          isCheckout: true,
        })
      );
    }
  };

  const getTotalAmount = (cartItem: CartPropType) => {
    let subtotal = Number(cartItem.menu_total );
    if (cartItem.menu_addons && cartItem.menu_addons.length > 0) {
      cartItem.menu_addons.forEach((addon) => {
        subtotal += addon.addons_price * addon.addons_quantity;
      });
    }
    if (cartItem.menu_variation && cartItem.menu_variation.length > 0) {
      cartItem.menu_variation.forEach((variation) => {
        subtotal += variation.price ;
      });
    }
    return subtotal ;
  };

  const totalAmount = cart.reduce((acc, item) => acc + getTotalAmount(item), 0);
  return (
    <div
      className="flex bg-lightBlack z-[9] fixed top-0 left-0 right-0 bottom-0  inset-0  transition-opacity delay-150"
      onClick={handleBackgroundClick}
    >
      <div className="w-[80%] md:w-[35%] xl:w-[30%] bg-white fixed top-0 bottom-0 z-[10] right-0 pt-5 md:pt-14">
        <div className="flex flex-col  items-center w-full h-full">
          <div className="flex flex-col p-4 border-b w-full">
            <div className="text-2xl font-medium">Your Cart</div>
            <div className="flex items-center gap-6 mt-4">
              <div className="flex items-center gap-2">
                <img src={Cart} alt="" className="w-5 h-5" />
                {cart.length === 0 ? (
                  <div className="text-base font-medium">
                    No items in your cart
                  </div>
                ) : (
                  <div className="text-base font-medium">
                    <span className="text-primary">{cart.length} items</span> in
                    your cart
                  </div>
                )}
              </div>
              <div className="flex items-center gap-2">
                <img src={Bike} alt="" className="w-6 h-6" />
                <div className="border-b border-primary w-2"></div>
              </div>
            </div>
          </div>

          {cart.length === 0 ? (
            <div className="flex flex-col self-center h-full items-center justify-center px-4 md:px-5 xl:px-20 gap-3">
              <img src={Empty} alt="" className="w-32 h-32" />
              <div className="font-bold text-xl text-center">
                Your Cart is Empty
              </div>
              <div className="text-sm text-[#9E9E9E] text-center">
                Looks like you haven’t added anything to your cart yet
              </div>
              <Button
                label="Shop Now"
                loading={false}
                onClick={onShop}
                color="orange"
              />
            </div>
          ) : (
            <div className="flex flex-col w-full h-full scrollbar-thin justify-between">
              <div className="flex flex-col">
                {cart.map((item) => (
                  <CartItemCard cart={item} key={item.menu_id} isDelete />
                ))}
              </div>
              <div className="flex flex-col w-full border-t gap-2">
                <div className="text-center font-medium border-b py-2">
                  Subtotal: {country!.country_currency_symbol} {totalAmount.toFixed(2)}
                </div>
                <div className="flex flex-col p-4 gap-2">
                  <Button
                    label="Proceed to Checkout"
                    loading={loading}
                    onClick={onCheckout}
                    color="orange"
                  />
                  <Button
                    label="Clear all"
                    loading={false}
                    onClick={() => dispatch(clearCart())}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CartPage;
